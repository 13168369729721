import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE } from 'Consts/routes';
import { OFFER_TYPE_DIAGNOSTIC } from 'Consts/offers';
import { USER_OFFER_USERS_TYPE_MULTI } from 'Consts/userOffers';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/userOffers/Editor';
import EventsStats from 'Components/trainer/events/Stats';
import DiagnosticReportsList from 'Components/trainer/userOfferDiagnosticReports/List';
import InvitedUsersList from 'Components/trainer/userOffers/InvitedUsersList';

export const TAB_DATA = 'data';
export const TAB_EVENTS_STATS = 'eventsStats';
export const TAB_DIAGNOSTIC_REPORTS = 'diagnosticReports';
export const TAB_USER_OFFER_SUB_USERS = 'userOfferSubUsers';

export default class TrainerUserOffer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userOffer: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { userOffer, location, history } = this.props;
        const { search } = location;

        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="trainer-user-offer"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userOffer}
                >
                    {userOffer && userOffer.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Zamówienie"
                            subHeadline={'Właściciel: ' + getFullName(userOffer.data.user).label}
                            controls={[{
                                isVisible: Boolean(queryObject.tab === TAB_DIAGNOSTIC_REPORTS),
                                key: 'userOfferDiagnosticReport',
                                label: 'Nowy raport diagnostyczny',
                                style: 'gradient',
                                layout: 'wide',
                                onClick: () => history.push(
                                    withVariables(
                                        TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE.path, {}, { userOfferId: userOffer.data.id, userId: userOffer.data.user.id }
                                    )
                                ),
                            }]}
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={userOffer.data}
                                        predefinedQuery={{
                                            userOfferId: userOffer.data.offer && userOffer.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_EVENTS_STATS,
                                label: 'Statystyki',
                                children: (
                                    <EventsStats
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userOfferId: userOffer.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_DIAGNOSTIC_REPORTS,
                                label: 'Raporty diagnostyczne',
                                visible: Boolean(userOffer.data.offer.type === OFFER_TYPE_DIAGNOSTIC),
                                children: (
                                    <DiagnosticReportsList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userOfferId: userOffer.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                visible: true,
                                key: TAB_USER_OFFER_SUB_USERS,
                                label: 'Zaproszeni użytkownicy',
                                visible: Boolean(userOffer.data.usersType === USER_OFFER_USERS_TYPE_MULTI),
                                children: (
                                    <InvitedUsersList 
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userOfferId: userOffer.data.id,
                                        }}
                                    />
                                ),
                            }]}
                        />
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

