import { connect } from 'react-redux';
import Component from './component';

import {
    getSubUsers,
} from 'Redux/modules/trainer/userOffers/actions';

export default connect(
    state => ({
        subUsers: state.trainerUserOffers.subUsers,
    }),
    dispatch => ({
        actions: {
            getSubUsers: dispatch(getSubUsers),
        },
    }),
)(Component);