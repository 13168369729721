import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;

        .block-left {
            overflow: hidden;
            position: relative;
            margin-right: 4em;
            width: 30%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        .block-right {
            width: 70%;

            .panel-view-header {
                .header {
                    margin-bottom: 3em;
                }
            }

            .boxes-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 2em;

                .admin-panel-text-box {
                    width: 31%;
                    padding: 1em 0;
                    margin-right: 1em;
                    margin-bottom: 1em;
                }
            }

            .products-block, .details-block {
                .title-block {
                    font-size: 1.3em;
                    font-weight: 700;
                    color: ${variables.dpc_borderDark};
                    text-transform: uppercase;
                    margin-bottom: 1em;
                }
            }
        }

        @media (max-width: ${variables.desktopS}) {
            .block-right {
                .boxes-container {
                    .admin-panel-text-box {
                        width: 47%;
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletL}) {
            flex-direction: column;

            .block-left {
                display: block;
                margin-bottom: 2em;
                height: 25em;
                width: 100%;
            }

            .block-right {
                width: 100%;
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .block-right {
                .boxes-container {
                    .admin-panel-text-box {
                        width: 47%;
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            flex-direction: column;

            .block-right {
                .boxes-container {
                    .admin-panel-text-box {
                        width: 100%;
                        margin-right: 0;
                    }
                }  
            }
        }
    `;
